@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}

	&.btn {
		color: $white;
		border: 1px solid $white;
		border-radius: rems(6);
		padding: rems(7) 0;
		text-align: center;
		text-transform: uppercase;
		background-color: transparent;
		width: 100%;
	}
}

header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: rems(95) 0 rems(85);
	z-index: 5;

	nav#nav-menu {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: rems(1181);
		position: relative;

		@include phone-down {
			display: none;
		}

		h1 {
			font-family: $primary-font;
			font-size: rems(20);
			margin: 0;
			cursor: pointer;
		}

		ul {
			list-style: none;
			display: flex;
			gap: rems(30);
			padding: 0;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			justify-content: space-between;
			width: 100%;
			max-width: rems(500);

			li {
				display: flex;
				align-items: center;
				justify-content: center;

				a {
					font-size: rems(20);
					text-transform: lowercase;
				}

			}
		}

		button {
			color: $white;
			text-transform: lowercase;
			background-color: transparent;
			border: none;
			font-size: rems(20);
		}
	}

	nav#mobile-nav-menu {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: rems(1181);
		transition: all .5s ease-in-out;

		@include tablet-up {
			display: none;
		}

		&.anchor-mobile-hero {
			transform: translateY(28vh);
		}

		ul {
			list-style: none;
			display: flex;
			justify-content: space-between;
			padding: 0;
			margin: 0;
			width: 100%;

			li {
				display: flex;
				align-items: center;
				justify-content: center;

				h1 {
					font-family: $primary-font;
					font-size: rems(14);
					margin: 0;
					cursor: pointer;
				}

				a {
					font-size: rems(14);
					text-transform: lowercase;
				}

				button {
					color: $white;
					text-transform: lowercase;
					background-color: transparent;
					border: none;
					font-size: rems(14);
				}
			}

		}
	}
}

main {
	height: 100vh;
	position: relative;

	section {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		width: 100%;
		max-width: rems(1200);
		min-height: 100vh;
		transition: all .6s ease-in-out;

		&:not(&.active) {
			opacity: 0;
			pointer-events: none;
		}

		.container {
			height: 100vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

		}
	}

	section#hero {
		.container {

			.fade-wrapper {
				width: 100%;
				height: 100vh;
				position: relative;

				#hero-image {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: all 1s ease-in-out;
				}

				button {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, 50%);
					font-size: rems(25);
					background-color: transparent;
					color: $white;
					opacity: 0;
					transition: all 1s ease-in-out;
					border: none;
					max-width: rems(1181);
					width: 100%;
				}

				video#hidden-video {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					font-size: rems(25);
					opacity: 0;
					pointer-events: none;
					transition: all .5s ease-in-out;
					max-width: rems(1181);
					width: 100%;
					min-height: rems(600);
					object-fit: cover;
					object-position: center;

					&.active {
						opacity: 1;
					}
				}

				@include hover-focus {

					button {
						opacity: 1;
					}

					img {
						opacity: 0;
					}
				}
			}
		}
	}

	section.music {
		.container {
			max-width: rems(1181);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: relative;
			top: rems(60);

			img {
				padding: rems(20);
			}


			.btn-wrapper {
				display: flex;
				flex-direction: column;
				gap: rems(25);
				width: 100%;
				max-width: rems(200);

				.btn {
					font-size: rems(12);
				}
			}
		}
	}

	section#video {

		.container {
			padding: rems(10);
			max-width: rems(856);

			// VIDEO CAROUSEL
			#video-carousel {
				position: relative;

				// Item
				.item {
					.embed-container {
						width: 100%;

						iframe {}
					}
				}

				.owl-stage-outer {}

				.owl-nav {

					// Nav Buttons
					button {
						color: $white;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);

						&.owl-prev {
							left: rems(15);
						}

						&.owl-next {
							right: rems(15);
						}

						.arrow {
							i {
								font-size: rems(43);

								@include hover-focus {
									transform: scale(1.1);
								}
							}
						}
					}
				}
			}
		}
	}

	section#email {
		.container {
			.form-container {

				form {
					display: flex;
					align-items: center;

					.form-group {
						.form-control {
							border: none;
							border-bottom: 1px solid white;
							background-color: transparent;
							color: white;
							outline: none;
							padding-left: 0;
							padding-bottom: rems(12);

							&::placeholder {
								color: white;
								opacity: .5;
								text-transform: lowercase;
							}
						}
					}
				}


				.email-button {
					margin-left: rems(30);
					color: $white;
					text-transform: lowercase;
					background-color: transparent;
					border: none;
					padding-bottom: rems(12);
				}
			}
		}
	}

	section#tour {
		color: $white;

		@include tablet-down {
			padding-top: 10vh;
			height: 110vh;
		}

		.container {
			// overflow-y: hidden;
			padding-top: rems(200);
			justify-content: flex-start;

			@include tablet-down {
				padding-top: rems(100);
			}

			#tour-dates {
				width: 100%;
				min-height: rems(100);
				max-width: rems(800);
				overflow-y: scroll;
				padding-right: 30px;


				&::-webkit-scrollbar-track {
					background-color: #0D0D0D;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $white;
					border-radius: 10px;
				}

				&::-webkit-scrollbar {
					width: 12px;
				}

				.event-group {
					display: flex;
					gap: rems(16);
					margin: rems(24) 0;
					text-transform: lowercase;
					font-size: rems(20);
					padding-bottom: rems(20);

					@include tablet-down {
						align-items: flex-start;
						justify-content: center;
					}

					.event-date {
						flex: .7;
					}

					.event-venue {
						flex: 2;
					}

					.event-location {
						flex: 2;
					}

					.event-links {
						flex: 1;
						text-align: right;
						display: flex;
						flex-direction: column;
						gap: rems(10);
						align-items: flex-end;
						justify-content: center;
						min-width: rems(90);

						a.link {
							display: block;
							width: rems(90);
							text-align: center;
							border: 1px solid $white;
							border-radius: 6px;
							text-transform: uppercase;
							font-size: rems(13);
							padding: rems(6) rems(12);

							@include hover-focus {
								opacity: .5;
							}

							@include tablet-down {
								font-size: rems(16);
							}
						}
					}
				}
			}
		}

		.no-events {
			text-align: center;
			display: block;
		}
	}
}

footer {
	text-align: center;
	font-size: rems(12);
	text-transform: uppercase;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}